<template>
	<div class="inquiry_overview" v-loading="loading">
		<div class="real_time" >
			<div class="tits">
				<div class="tit">实时概况</div>
			</div>
			<div class="contents" v-if="overview_data && overview_data.stat_data">
				<div class="item">
					<div class="num">{{overview_data.stat_data.today_inquiry}}</div>
					<div class="desc">今日询价数</div>
				</div>
				<div class="item">
					<div class="num">{{overview_data.stat_data.today_quotation}}</div>
					<div class="desc">今日报价数</div>
				</div>
				<div class="item">
					<div class="num">{{overview_data.stat_data.today_examine}}</div>
					<div class="desc">今日审计数</div>
				</div>
				<div class="item">
					<div class="num">{{overview_data.stat_data.today_order}}</div>
					<div class="desc">今日成交数</div>
				</div>
				<div class="item">
					<div class="num">{{overview_data.stat_data.sum_inquiry}}</div>
					<div class="desc">询价总数</div>
				</div>
				<div class="item">
					<div class="num">{{overview_data.stat_data.sum_quotation}}</div>
					<div class="desc">报价总数</div>
				</div>
				<div class="item">
					<div class="num">{{overview_data.stat_data.sum_examine}}</div>
					<div class="desc">审计总数</div>
				</div>
				<div class="item">
					<div class="num">{{overview_data.stat_data.sum_order}}</div>
					<div class="desc">成交总数</div>
				</div>
			</div>
		</div>
		<div class="data_show">
			<div class="left">
				<div class="tit">近十天订单数(个)</div>
				<div id="order_num" class="order_num"></div>
			</div>
			<div class="right">
				<div class="tit">销售额(元)</div>
				<div id="sale_money" class="order_num"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import { overviewData } from '@/api/organization'
	export default {
		data() {
			return {
				overview_data:{},
				loading:true,
			}
		},
		created() {
			this.getOverviewData();
		},
		methods: {
			getOverviewData(){
				overviewData()
				.then(res=>{
					if(res.code>=0){
						this.overview_data = res.data;
						this.getEcharts();
						this.loading = false
					}
				})
			},
			getEcharts() {
				var date_arr = [];
				var curTime = new Date().getTime();
				for(var i = 0;i<10;i++){
					var startDate = curTime - (i * 3600 * 24 * 1000);
					startDate = this.$util.timeStampTurnTime(startDate/1000).split(' ')[0]
					startDate = startDate.substring( startDate.indexOf('-')+1 )
					date_arr.unshift(startDate)
				}
				var myChart = this.$echarts.init(document.getElementById('order_num'));
				var option = {
					grid: {
					    top: '20px',
					    left: '25px',
					    right: '25px',
					    bottom: '5%',
					    containLabel: true
					},
					tooltip:{
						trigger:'item'
					},
				    xAxis: {
				        data: date_arr,
						axisLabel: {
						     interval:1,//横轴信息全部显示
						},
				    },
				    yAxis: {
						type:'value'
					},
				    series: [{
				        type: 'bar',
				        data: this.overview_data.stat_data.statistics.inquiry_count,
						itemStyle: {
							barWidth: 5, // 设置柱子粗细
						},
				    }]
				};
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
				var myChart1 = this.$echarts.init(document.getElementById('sale_money'));
				var option1 = {
					grid: {
					    top: '20px',
					    left: '25px',
					    right: '25px',
					    bottom: '5%',
					    containLabel: true
					},
					tooltip:{
						trigger:'axis'
					},
				    xAxis: {
				        data: date_arr,
						axisLabel: {
							interval:1,//横轴信息全部显示
							textStyle:{
								// fontSize:'11'
							},
						},
				    },
				    yAxis: {
						type:'value',
					},
				    series: [{
				        type: 'line',
				        data: this.overview_data.stat_data.statistics.inquiry_total,
						itemStyle: {
							barWidth: 5, // 设置柱子粗细
						},
				    }]
				};
				// 使用刚指定的配置项和数据显示图表。
				myChart1.setOption(option1);
			}
		},
	}
</script>

<style lang="scss">
	.inquiry_overview{
		min-height: 730px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		flex-direction: column;
		.real_time{
			width: 100%;
			background-color: #fff;
			margin-bottom: 18px;
			.tits{
				padding: 14px 0 0 0;
				margin-left: 18px;
				display: flex;
				align-items: center;
				.tit{
					color: #333;
					font-size: 15px;
					line-height: 20px;
					margin-right: 13px;
				}
				span{
					color:#858585;
					border-radius: 7px;
					font-size: 12px;
					line-height: 12px;
				}
			}
			.contents{
				display: flex;
				align-items: center;
				flex-wrap:wrap;
				padding: 18px 32px 31px 18px;
				.item{
					display: flex;
					align-items: center;
					flex-direction: column;
					padding: 14px 0;
					box-sizing:border-box;
					background-color: #F7F7F7;
					margin-right: 24px;
					margin-top:24px;
					width: calc( ( 100% - 72px ) / 4 );
					.num{
						color: #00A98F;
						font-size: 26px;
						line-height: 36px;
						margin-bottom: 2px;
					}
					.desc{
						color: #333;
						font-size: 12px;
						line-height: 16px;
					}
					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4){
						margin-top: 0;
					}
					&:nth-child(4n){
						margin-right: 0;
					}
				}
			}
		}
		.data_show{
			width: 100%;
			flex: 1;
			display: flex;
			align-items: flex-start;
			.left,
			.right{
				background-color: #fff;
				flex:1;
				height: 440px;
			}
			.left{
				margin-right: 15px;
			}
			.tit{
				font-size: 14px;
				line-height: 16px;
				padding: 15px 0 0 25px;
			}
			.order_num{
				height: 409px;
			}
		}
	}
</style>